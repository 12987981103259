body {
  background-color: rgb(238, 238, 238);
}

.App {
  text-align: center;
}

.search_button {
  background-color: #363636;
  border: none;
  color: white;
  padding: 3px 8px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  border-radius: 3px;
}

.site_name {
  color: rgb(43, 43, 43);
  font-size: 40px;
  margin-top: 4%;
}

.introduction {
  margin-bottom: 40px;
}

.language {
  margin: 15px;
}

.location {
  margin: 15px;
}

.profile_card {
  border: 2px solid rgb(82, 82, 82);
  padding: 0.5rem;
  border-radius: 6px;
  width: 50%;
  margin-right: auto;
  margin-left: auto;
  margin-top: 15px;
  display: flex;
  flex-direction: column;
}
.profile_card_main {
  display: flex;
}

.profile_picture {
  height: 50px;
  width: 50px;
  border-radius: 100%;
  margin-left: 2%;
  vertical-align: bottom;
}

.profile_title {
  margin-left: 10px;
}

.profile_card a {
  text-decoration: none;
  color: #363636;
  cursor: pointer;
}

.profile_card_header {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
}

.profile_card_buttons {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: right;
}

.more_hide_button {
  background-color: #363636;
  border: none;
  color: white;
  padding: 3px 8px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 12px;
  border-radius: 3px;
  width: 50px;
}

.profile_card_details {
  display: none;
  flex-wrap: wrap;
}

.profile_card_details ul {
  list-style-type: none;
  text-align: left;
}

.profile_card_details ul li {
  margin-top: 10px;
}

.profile_card_details ul li p {
  display: inline;
}

.main_result_area {
  margin-top: 40px;
}